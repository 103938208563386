body {
  font-family: sans-serif;
  overflow-x: hidden;
  margin-bottom: 100px;
  overflow-x: hidden;
  background-color: #f2f2f2;
}

.img-style {
  border-radius: 1vw;
  position: relative;
  top: -5vw;
  z-index: 1;
}

main {
  z-index: 1;
  background-color: #f2f2f2;
}

html {
  scroll-behavior: smooth;
}

p,
span:not(#header span, footer span, .dot span) {
  color: #333333;
  font-size: 1.4vw;
}

footer {
  background-color: #b30000 !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 0;
  color: white !important;
  padding: 1vw 3vw 0.5vw 3vw !important;
}

.menu-items {
  align-items: start;
}

h1,
h5,
.bluScuro {
  color: #b30000;
  font-size: 2vw;
}

.background::before {
  height: 125vh;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

#header {
  position: fixed;
  width: 100%;
  color: white;
  line-height: 2.5vw;
  z-index: 2;
  transition: all 0.4s ease;
  height: 6vw;
  padding: 0 6vw;
}

/* Stile quando la finestra è scrollata in alto */
#header:not(.is-fixed) {
  background-color: rgba(255, 255, 255, 0);
  /* Imposta lo sfondo trasparente */
}

/* Stile quando la finestra è scrollata verso il basso */
#header.is-fixed {
  background-color: white;
  /* Imposta lo sfondo bianco */
  height: 4vw;
  /* Modifica l'altezza */
}

.dot {
  background-color: #b85555;
  color: white;
  text-align: center;
  font-size: 1.4vw;
  font-weight: 700;
  height: 6vw;
  place-content: center;
  width: 6vw;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.box-pallino {
  padding: 5%;
}

.box-pallino:hover .dot {
  height: 8vw;
  width: 8vw;
}

.dot span {
  background-color: #b30000;
  color: white;
  height: 6vw;
  place-content: center;
  width: 6vw;
  border-radius: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}

.logo {
  width: 3vw;
  transition: filter 0.4s ease;
  /* Aggiungi una transizione per rendere il cambio di filtro più fluido */
}

/* Stile quando l'elemento è scrollato */
.is-fixed .logo {
  filter: brightness(0);
}

/* Stile quando l'elemento non è scrollato */
.logo {
  filter: brightness(1);
}

.text-logo {
  line-height: 1em;
  display: flex;
  flex-direction: column;
  font-size: 2.5vw;
}

.title {
  width: 70%;
  position: absolute;
  left: 15%;
  text-align: center;
  color: white;
  top: 25vw;
}

.white-svg {
  fill: #f4f4f4;
  position: relative;
  top: 110vh;
}

.card-container {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: -10vw;
}

.primoParagrafo {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50vw;
  position: absolute;
  left: 5%;
}

.primoParagrafo h2 {
  font-size: 3vw;
  font-weight: 400;
  color: white;
}

.primoParagrafo p {
  font-size: 1.5vw;
  font-weight: 300;
  color: white;
}

.paragrafo.noPadding div:first-child {
  padding: 5%;
}

.paragrafo.sfondoRosso {
  background-color: #fceeee;
}

.paragrafo.reverse {
  flex-direction: row-reverse;
}

.paragrafo {
  height: fit-content;
  min-height: 110vh;
  display: flex;
  padding: 5%;
  color: #f4f4f4;
  align-items: center;
  justify-content: space-around;
  background-color: #f2f2f2;
  flex-direction: row;
  flex-wrap: wrap;
}

.paragrafo.noPadding {
  padding: 0;
}

.paragrafo > div:not(.card-finali) {
  width: 50%;
}

.paragrafo > img {
  width: 40%;
}

.info-menu {
  font-size: 1vw;
  border-bottom: 1px #ffffff3d solid;
  height: 40%;
}

.info-menu.is-fixed {
  height: 0;
  visibility: collapse;
}

.titolo-card div {
  text-align: center;
}

.titolo-card {
  font-size: 1.5vw;
  width: 100%;
  padding: 1vw 0;
}


.lingua {
  position: absolute;
  float: right;
  right: 6vw;
  top: 50%;
  transform: translateY(0%);
}

.lingua.is-fixed {
  transform: translateY(-50%);
}

/* Stili per dispositivi mobili */
@media screen and (max-width: 768px) {
  .dot {
    height: 5vh;
    width: 5vh;
  }

  .box-pallino:hover .dot {
    height: 8vh;
    width: 8vh;
  }

  .dot span {
    height: 5vh;
    width: 5vh;
  }

  .background::before {
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.5) 00%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .primoParagrafo {
    width: 90%;
  }

  .menu-items {
    align-items: center;
  }

  .card-finali {
    width: 90%;
  }

  .titolo-card {
    font-size: 2.5vh;
  }

  .paragrafo > img {
    width: 90%;
  }

  .paragrafo.noPadding div:nth-child(2) {
    width: 100%;
  }

  .pallini {
    flex-direction: column;
    height: fit-content;
  }

  p,
  span:not(#header span) {
    font-size: 2vh !important;
  }

  h1,
  h5,
  .bluScuro {
    font-size: 2.5vh;
  }

  .primoParagrafo h2 {
    font-size: 3vh;
  }

  .primoParagrafo p {
    font-size: 2vh;
  }

  .paragrafo > div:not(.card-finali) {
    width: 90%;
  }

  .analisi {
    height: 80vh;
    background-position-x: 80%;
  }

  .card-container {
    padding: 0 5vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
  }

  .card-container div {
    flex: 0 0 100%;
  }

  .white-svg {
    top: 121vh;
  }

  .lingua {
    transform: translateY(-50%);
  }

  #header {
    height: 8vh !important;
    padding: 0.5vh 2vh;
  }

  .info-menu {
    display: none;
  }

  .logo {
    width: 5vh;
    transition: filter 0.4s ease;
  }

  .text-logo {
    font-size: 4vh;
  }
}

/* Stili per dispositivi desktop */
@media screen and (min-width: 769px) {
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b30000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #900000;
}

.App {
  text-align: center;
}

.background {
  background-image: url(images/sfondo.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1a1a2e;
  height: 125vh;
  background-attachment: fixed; /* Imposta l'immagine di sfondo come "fissa" */
}

/* Effetto parallasse */
.background::after {
  content: '';
  background: rgba(0, 0, 0, 0.4); /* Opacità per migliorare la leggibilità */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Animazione del parallasse */
@keyframes parallax {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-50%);
  }
}

.background::after {
  animation: parallax 10s infinite alternate; /* Modifica la velocità o la durata a tuo piacimento */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
